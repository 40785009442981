import { useState } from "react";
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import { 
  setDeviceSettings, 
  selectCurrentComponentDesc,
  selectComponentValues,
  currentSettingsSet
} from '../data/devicesSlice.js';

import { 
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  Stack,
  Typography,
  Select,
} from '@mui/material/';

import useParamSelector from "../data/useParamSelector.js";

const PureAlarmTypeSetters = ({t, block}) => {
  const dispatch = useDispatch();
  const componentId = block.id;
  const data = useParamSelector(selectCurrentComponentDesc, componentId);
  const desc = data.description;
  const defaultLimitType = desc.DEFAULT_VALUE ?? 1;
  const currentValue = useParamSelector(selectComponentValues, componentId)[0]; 
  const titles = desc.TITLE;
  //const switchLabels = desc.LABELS[0];
  const selectLabels = desc.LABELS.map( l => l[0]);
  const selectValues = desc.LABELS.map( (l,i) => l[1] ?? i);
  const selectInfos = desc.LABELS.map( l => l[2] ?? false);
  
  const [limitType, setLimitType] = useState(currentValue ?? defaultLimitType);
  const setSetting = (value) => {
    dispatch(setDeviceSettings({
      jsonkeys: desc.jsonkeys, 
      jsonValInd: desc.JSONValInd, 
      values: [value]
    }));
  }

  const onSwitchChange = (event) => {
    setSetting(event.target.checked ? limitType : 0)
  }

  const onSelectChange = (event) => {
    setLimitType(Number(event.target.value));
    dispatch(currentSettingsSet({
      values: [parseInt(event.target.value)],
      jsonkeys: data.jsonkeys,
      valInd: data.JSONValInd
    }));
  }

  return (
    <Box sx={{display: "flex", clear:"both"}}>
      {/* <FormControl style={{textAlign: "left", clear: "both", marginLeft: "1em"}}>
         <FormLabel>{t(titles[0])}</FormLabel>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>{t(switchLabels[0])}</Typography>
          <Switch
            id="switch"
            checked={ !!value }
            style={{ margin: "0"}}
            onChange={ onSwitchChange }
          />
          <Typography>{t(switchLabels[1])}</Typography>
        </Stack> 
        <FormLabel>{t(titles[1])}</FormLabel>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          value={ limitType }
          onChange={ onRadioChange }
        >
          {radioLabels.map ( (label, i) =>
            <FormControlLabel id={"radio" + i} key={label} value={i + 1} control={<Radio />} label={t(label)} />
          )}
        </RadioGroup>
      </FormControl> */}
      <FormControl size="small" sx={{textAlign: "left", marginLeft: "1em", minWidth: 120}}>
        <FormLabel>{t(titles[1])}</FormLabel>
        <Select
          name="select"
          value={limitType}
          onChange={onSelectChange}
          sx={{marginTop:".5em"}}
        >
          {selectLabels.map ( (label, i) =>
            <MenuItem key={label} value={selectValues[i]}>{t(label)}</MenuItem> 
          )}
        </Select>
      </FormControl>
      <Box sx={{marginTop: "3em", marginLeft: 2, fontSize: "small"}}>
        {t(selectInfos[selectValues.indexOf(limitType)])}
      </Box>
    </Box>
  );
};

const AlarmTypeSetters = withTranslation()(PureAlarmTypeSetters);

export { AlarmTypeSetters };