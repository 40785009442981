/* eslint-disable no-dupe-keys */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      false: false, // shows nothing
      "header-help-link": "HELP",
      "header-help-link-tooltip": "Open a help page",
      "select-language": "Change language",
      "page-not-found-title": "Page not found!",
      "page-not-found-text": "Please, check your link.",
      "error-alert-title": "Error!",
      "warning-alert-title": "Warning!",
      "info-alert-title": "Info!",
      "success-alert-title": "Success!",
      "token-expired": "Link has expired. Please request new status message and try again.",
      "token-invalid": "Invalid link. Please request new status message and try again.",
      "invalid-url-code": "Invalide QR code. Please try to read the again.",
      "setup-dialog-title": "Device setup",
      "setup-dialog-info-1": "Activate the device by adding a phone number and PIN code for superadmin.",
      "setup-dialog-2-alertinfo": "The phone number and the PIN code added for superadmin.",
      "setup-dialog-info-2": "Please confirm the phone number and activate Main-IoT alarm service in Telegram application.",
      "setup-dialog-info-2-more": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access. Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "setup-dialog-pin-text": "Create a six-digit PIN code. Keep the code in safe place!", 
      "pin-error": "PIN code is too short.",
      "setup-number-error": "Enter a phone number.",
      "activate-bot": "Activate Main-IoT Bot",
      "next": "Next",
      "back": "Back",
      "have-read": "I have read the document.",
      "agree": "Agree",
      "pin-change-info": "The PIN code can be used along with the QR code activation link (delivered with the device) to change device settings without Telegram access.",
      "pin-change-info-more": "Notice, if superadmin cannot log in any more with their account and if the PIN code is lost, the phone number of superadmin cannot be changed or unlinked from the device. When logging in with the PIN code you have access to all settings and can set a new superadmin and PIN code. Login page can be accessed by reading the activation QR code delivered with device.",
      "no-privileges-to-change-pin-text": "Login as superadmin or with the PIN code to change the code.",
      "user-type": "user type",
      "your-priviledges": "Your priviledges",
      "user": "user",
      "guest": "guest",
      "admin": "admin",
      "superadmin": "superadmin",
      "logout": "Log out",
      "login": "Log in",
      "enter-pin": "Enter device PIN to log in.",
      "pin-code": "PIN code",
      "pin-incorrect": "Incorrect PIN code.",
      "pin-locked": "Too many failed login attempts. Pin code is locked for a short while.",
      "change-pin": "save PIN",
      "enter-new-pin": "new PIN code",
      "create-new-pin": "create a PIN code",
      "pin-is-saved": "New PIN code saved!",
      "generate-report": "generate report",
      "download-report": "download report",
      "report": "Report",
      "select-range": "Select range",
      "range-start": "Range start",
      "range-end": "Range end",
      "range-start-end-error": "Range must start before it ends!",
      "get-data": "Get data",
      "search-device": "Search device",
      "loading": "Loading...",
      "device-not-found": "Device not found!",
      "multiguard": "MultiGuard",
      "device-id": "Device ID",
      "search": "Search",
      "my-multiguard": "My MultiGuard",
      "device-no-data": "Device has no user data.",
      "device-name": "Device name",
      "device-id": "Device id",
      "id": "id",
      "device-type": "Device type",
      "type": "type",
      "uuid": "UUID",
      "status": "Status",
      "last-seen": "Last seen",
      "activated": "Activated",
      "time-left": "Time left",
      "not-found": "not found",
      "no-data": "NO DATA",
      "activation-date-unknown": "activation date unknown",
      "saved-phonenumbers": "Saved phone numbers",
      "phonenumber": "Phone number",
      "phonenumbers": "Phone numbers",
      "phonenumbers-info-inDialog-1": "Please, add a phone number for superadmin in order to use the device.",// If the number below is marked with ❌, it has not been confirmed by the owner. This must be done using ",
      "with-telegram-bot": "Main-IoT Telegram bot",
      "phonenumbers-info-inDialog-3": ". After confirmation only the owner can unlink the number from the device, or it must be done by logging in using the PIN code.",
      "phonenumbers-info": `These numbers are used to send to alarm messages and voice alarms. If a number is marked with ❌, the number is not in use. Please ask the owner of the number to confirm the number with `,
      "telegram-bot": "The Telegram bot",
      "edit-numbers": "Edit numbers",
      "hide-edit": "Hide edit",
      "insert-phonenumber": "A new phone number",
      "add": "add",
      "save-phonenumbers": "Save numbers",
      "restore-saved-phonenumbers": "Restore saved numbers",
      "no-phone-numbers-set": "No phone numbers set",
      "undo": "Undo",
      "save": "Save",
      "sensitivity-buttons": "Sensor sensitivity",
      "button-off": "Off",
      "button-sensitive": "Sensitive",
      "button-insensitive": "Insensitive",
      "button-sensitive-info-pressure": "Inline.",
      "button-insensitive-info-pressure": "Tank pressure.",
      "send-settings": "Send settings",
      "settings": "Settings",
      "device-info": "Device info",
      "sensor-settings": "Sensor settings",
      "push-the-button": "Push the button from device to continue install process.",
      "cancel": "Cancel",
      "unit-system": "Unit system",
      "hour-cycle": "Clock",
      "24h": "24-hour",
      "12h": "12-hour",
      "settings-tab": "Settings",
      "advanced-tab": "Advanced",
      "updating-settings-info-text": "Settings will be updated to device within 10 minutes.",
      "data-tab": "Data",
      "store-tab": "Store",
      "devices-tab": "Devices",
      "press-update-notify": "Press UPDATE to confirm changes.",
      "advanced-settings": "Advanced",
      "three-hours": "3 hours",
      "one-day": "24h",
      "one-week": "a week",
      "one-month": "month",
      "custom-timerange": "custom",
      "from-reference-time": "From reference",
      "reference-time-button": "ref",
      "three-hours-button": "3h",
      "one-day-button": "24h",
      "one-week-button": "1w",
      "one-month-button": "1m",
      "custom-timerange-button": "set",
      "time-range": "Time range",
      "time-range-start": "Start",
      "time-range-end": "End",
      "latest-reboot": "Reference",
      "aaa_general-abbreviation": "Gen",
      "temperature-abbreviation": "Temp",
      "humidity-abbreviation": "Hum",
      "voc-abbreviation": "VOC",
      "co2-abbreviation": "CO2",
      "pir-abbreviation": "PIR",
      "lux-abbreviation": "Lux",
      "spl-abbreviation": "SPL",
      "pressure-abbreviation": "Pres",
      "batteryVoltage-abbreviation": "Bat",
      "reference-value": "Reference value",
      "reference-value-abbreviation": "Ref. value",
      "latest-value": "latest value",
      "limit-type": "Alarm limit type",
      "alarm-limit-type-off": "Off",
      "alarm-limit-type-off-info": "Alarm off.",
      "alarm-limit-type-absolute": "Absolute",
      "alarm-limit-type-absolute-info": "User adjustable, fixed alarm limits.",
      "alarm-limit-type-reference-based": "Reference",
      "alarm-limit-type-reference-based-info": "The limits are relative to the reference value.",
      "alarm-suspend-time-1": "Alarm suspend time (Temp, Hum, VOC, CO2)",
      "alarm-suspend-time-2": "Alarm suspend time (PIR, Lux, SPL)",
      "pir-inactivity-time": "PIR inactivity time (alarm low limit)",
      "uplink-idle-interval": "Uplink idle interval",
      "uplink-idle": "Uplink idle",
      "downlink-idle": "Downlink idle",
      "alarm-disable-1": "Alarm disable 1",
      "alarm-disable-2": "Alarm disable 2",
      "downlinks-per-day": "Downlinks per day",
      "use-average-values": "Use average values",
      "alarm": "Alarm",
      "yes": "yes",
      "no": "no",
      "off": "off",
      "on": "on",
      "ok": "ok",
      "cancel": "cancel",
      "co2-auto-calibration": "CO2 auto calibration",
      "alarm-sensitivity-settings": "Temperature, humidity, VOC, CO2 alarm sensitivity setting",
      "sec-0": "0",
      "sec-10": "10sec",
      "sec-30": "30sec",
      "sec-120": "2min",
      "lux-alarm-sens": "Lux alarm sensitivity setting",
      "spl-alarm-sens": "SPL alarm sensitivity setting",
      "alarm-idle-type": "Alarm idle type",
      "regenerative": "Regenerative",
      "one-shot": "One-shot",
      "double-message": "Double message per alarm",
      "not-yet-synced": "Settings not yet synced with MultiGuard",
      "select-sensor": "Select sensor to enable alarm limits",
      "alarm-limits": "Alarm limits",
      "tmp-alarm-limits": "Temperature alarm limits",
      "hum-alarm-limits": "Humidity alarm limits",
      "voc-alarm-limits": "VOC alarm limits",
      "co2-alarm-limits": "CO2 alarm limits",
      "lux-alarm-limits": "LUX alarm limits",
      "pir-alarm-limits": "PIR alarm limits",
      "spl-alarm-limits": "SPL alarm limits",
      "pressure-alarm-limits": "Pressure alarm limits",
      "uplink-interval": "Uplink interval",
      "required": "Required",
      "user-numbers": "These numbers are used to send alarm messages and voice alarms.",
      "confirm": "Confirm",
      "code": "code",
      "return": "Return",
      "update": "Update",
      "remove-account": "Remove account",
      "information-registered": "settings updated.",
      "numbers-to-save": "Save these numbers to your phone.",
      "sms-number": "SMS number",
      "voice-number": "Voice calls number",
      
      "temperature": "Temperature",
      "humidity": "Humidity",
      "voc": "VOC",
      "co2": "CO2",
      "pir": "PIR",
      "lux": "Lux",
      "spl": "SPL",
      "aaa_general": "General settings",
      "pressure": "Pressure",
      "batteryVoltage": "Battery Voltage",

      // Setting and data labels
      "label-sAmao": "max overshoot",
      "label-sAmad": "max drop",
      "label-sAhlt": "upper limit",
      "label-sAllt": "lower limit",
    }
  },
  fi: {
    translation: {
      false: false, // shows nothing
      "header-help-link": "TUKI",
      "header-help-link-tooltip": "Avaa opastussivu",
      "select-language": "Vaihda kieli",
      "page-not-found-title": "Sivua ei löydy!",
      "page-not-found-text": "Tarkista, että linkkisi on oikea.",
      "error-alert-title": "Virhe!",
      "warning-alert-title": "Varoitus!",
      "info-alert-title": "Info!",
      "success-alert-title": "Onnistui!",
      "token-expired": "Linkki on vanhentunut. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "token-invalid": "Linkki ei toimi. Pyydä uutta statusviestiä ja yritä uudelleen.",
      "invalid-url-code": "Virheellinen QR-koodi. Kokeile lukea koodi uudelleen.",
      "setup-dialog-title": "Laitteen käyttöönotto",
      "setup-dialog-info-1": "Aktivoi laite lisäämällä pääkäyttäjän puhelinnumero ja PIN-koodi.",
      "setup-dialog-2-alertinfo": "Puhelinnumero ja PIN-koodi lisätty superadmille.",
      "setup-dialog-info-2": "Varmenna numero ja aktivoi Main-IoT-hälytyspalvelu Telegram-sovelluksessa.",
      "setup-dialog-info-2-more": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta. Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numeroa ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä, PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.", 
      "setup-dialog-pin-text": "Luo oma kuusinumeroinen PIN-koodisi. Pidä PIN-koodi varmassa tallessa!",
      "pin-error": "PIN-koodi on liian lyhyt.",
      "setup-number-error": "Anna puhelinnumero.",
      "activate-bot": "Aktivoi Main-IoT-botti",
      "next": "Jatka",
      "back": "Takaisin",
      "have-read": "Olen lukenut dokumentin.",
      "agree": "Hyväksyn",
      "pin-change-info": "PIN-koodin ja laitteen mukana toimitettavan QR-aktivointikoodin avulla laitteen asetuksia voidaan muuttaa ilman kirjautumista Telegramin kautta.",
      "pin-change-info-more": "Huomaa, että jos pääkäyttäjä ei pääse enää kirjautumaan sisään tunnuksillaan ja jos PIN-koodi on kadonnut, pääkäyttäjän numero ei voi vaihtaa tai poistaa laitteelta. Vain kirjautumalla PIN-koodilla tai pääkäyttäjänä PIN-koodi tai pääkäyttäjän puhelinnnumero voidaan vaihtaa tai poistaa. Kirjautumissivulle pääsee lukemalla laitteen mukana toimitettavan QR-koodin.",
      "no-privileges-to-change-pin-text": "Kirjaudu pääkäyttäjänä tai PIN-koodilla vaihtaaksesi PIN-koodin.",
      "user-type": "käyttäjätyyppi",
      "your-priviledges": "Käyttäjäoikeutesi",
      "user": "käyttäjä",
      "guest": "vieras",
      "admin": "ylläpitäjä",
      "superadmin": "pääkäyttäjä",
      "logout": "Kirjaudu ulos",
      "login": "Kirjaudu",
      "enter-pin": "Syötä laitteen PIN-koodi kirjautuaksesi sisään.",
      "pin-code": "PIN-koodi",
      "pin-incorrect": "Virheellinen PIN-koodi.",
      "pin-locked": "Liian monta epäonnistunutta kirjautumisyritystä. PIN-koodi on lukittu hetkeksi.",
      "change-pin": "tallenna PIN",
      "enter-new-pin": "uusi PIN-koodi",
      "create-new-pin": "keksi PIN-koodi",
      "pin-is-saved": "Uusi PIN-koodi tallennettu laitteelle!",
      "generate-report": "luo raportti",
      "download-report": "lataa raportti",
      "report": "Raportti",
      "select-range": "Valitse tarkasteluväli",
      "range-start": "Välin alku",
      "range-end": "Välin loppu",
      "range-start-end-error": "Aikavälin alun täytyy olla ennen loppua.",
      "get-data": "Hae data",
      "search-device": "Etsi laite",
      "loading": "Ladataan...",
      "device-not-found": "Laitetta ei löydy!",
      "multiguard": "MultiGuard",
      "device-id": "Laitteen ID",
      "search": "Etsi",
      "my-multiguard": "Minun MultiGuardini",
      "device-no-data": "Laitteelle ei ole käyttäjätietoja.",
      "device-name": "Laitteen nimi",
      "device-type": "Laitteen tyyppi",
      "type": "tyyppi",
      "device-id": "Laitteen id",
      "id": "id",
      "uuid": "UUID",
      "status": "Status",
      "last-seen": "Viimeksi nähty",
      "activated": "Aktivoitu",
      "time-left": "Aikaa jäljellä",
      "not-found": "ei löydy",
      "no-data": "EI DATAA",
      "activation-date-unknown": "aktivointiaikaa ei löydy",
      "saved-phonenumbers": "Tallennetut puhelinnumerot",
      "phonenumber": "Puhelinnumero",
      "phonenumbers": "Puhelinnumerot",
      "phonenumbers-info-inDialog-1": "Lisää pääkäyttäjän numero laitteelle. Jos alla näkyvä numero on punainen, käyttäjä ei ole varmantanut sitä. Varmennus on tehtävä ",
      "with-telegram-bot": "Main-IoT Telegram -botilla",
      "phonenumbers-info-inDialog-3": ", ja varmentamisen jälkeen vain pääkäyttäjä voi poistaa oman numeronsa.",
      "phonenumbers-info": "Näitä numeroita käytetään hälytysviestien ja puheluhälytysten lähettämiseen. Jos numero on merkitty punaisella ruksilla, se ei ole käytössä. Pyydä tällöin numeron haltijaa hyväksymään sen käyttö ",
      "telegram-bot": "Telegram-botti",
      "edit-numbers": "Muokkaa numeroita",
      "hide-edit": "piilota",
      "insert-phonenumber": "Uusi puhelinnnumero",
      "save-phonenumbers": "Tallenna numerot",
      "restore-saved-phonenumbers": "Palauta numerot",
      "no-phone-numbers-set": "Puhelinnumeroita ei ole asetettu",
      "undo": "Peruuta",
      "send-settings": "Lähetä asetukset",
      "save": "Tallenna",
      "add": "lisää",
      "sensitivity-buttons": "Anturiherkkyys",
      "button-off": "Pois päältä",
      "button-sensitive": "Herkkä",
      "button-insensitive": "Epäherkkä",
      "button-sensitive-info-pressure": "Kytketty linjaan.",
      "button-insensitive-info-pressure": "Kytketty suoraan kompressoriin.",
      "settings": "Asetukset",
      "device-info": "Laitteen tiedot",
      "sensor-settings": "Sensoriasetukset",
      "push-the-button": "Paina laitteen painiketta jatkaaksesi asennusprosessia.",
      "cancel": "Peruuta",
      "unit-system": "Yksiköt",
      "hour-cycle": "Kello",
      "24h": "24-tuntinen",
      "12h": "12-tuntinen",
      "settings-tab": "Asetukset",
      "advanced-tab": "Edistynyt",
      "updating-settings-info-text": "Asetukset päivittyvät laitteelle 10 minuutin sisällä.",
      "data-tab": "Data",
      "store-tab": "Kauppa",
      "devices-tab": "Laitteesi",
      "press-update-notify": "Paina PÄIVITÄ vahvistaaksesi muutokset.",
      "advanced-settings": "Lisäasetukset",
      "three-hours": "3 tuntia",
      "one-day": "vuorokausi",
      "one-week": "viikko",
      "one-month": "kuukausi",
      "custom-timerange": "mukautettu",
      "from-reference-time": "Referenssimittauksesta",
      "reference-time-button": "ref",
      "three-hours-button": "3t",
      "one-day-button": "24t",
      "one-week-button": "1v",
      "one-month-button": "1kk",
      "custom-timerange-button": "valitse",
      "time-range": "Aikaväli",
      "time-range-start": "Alku",
      "time-range-end": "Loppu",
      "latest-reboot": "Referenssi",
      "aaa_general-abbreviation": "Yleis",
      "temperature-abbreviation": "Lämp",
      "humidity-abbreviation": "Kost",
      "voc-abbreviation": "VOC",
      "co2-abbreviation": "CO2",
      "pir-abbreviation": "Liike",
      "lux-abbreviation": "Valo",
      "spl-abbreviation": "Äänenpaine",
      "pressure-abbreviation": "Paine",
      "batteryVoltage-abbreviation": "Akku",
      "reference-value": "Referenssiarvo",
      "reference-value-abbreviation": "Ref.-arvo",
      "latest-value": "viimeisin arvo",
      "limit-type": "Hälytysrajatyyppi",
      "alarm-limit-type-off": "Pois",
      "alarm-limit-type-off-info": "Hälytys pois päältä.",
      "alarm-limit-type-absolute": "Absoluuttinen",
      "alarm-limit-type-absolute-info": "Käyttäjän asettamat hälytysrajat.",
      "alarm-limit-type-reference-based": "Referenssi",
      "alarm-limit-type-reference-based-info": "Rajat lasketaan suhteellisesti referenssiarvosta.",
      "alarm-suspend-time-1": "Hälytyksen toistuvuus (Lämpö, kosteus, VOC, CO2)",
      "alarm-suspend-time-2": "Hälytyksen toistuvuus (liike, valoisuus, äänenpaine)",
      "pir-inactivity-time": "Liiketunnistimen lähetysväli (alarm low limit)",
      "uplink-idle-interval": "Viestien lähetysväli lepotilassa",
      "uplink-idle": "Laitteen lepotilan ajastus",
      "downlink-idle": "Viestien vastaanottotauko",
      "alarm-disable-1": "Hälytykset pois käytöstä 1",
      "alarm-disable-2": "Hälytykset pois käytöstä 2",
      "downlinks-per-day": "Viestien vastaanotto /pv",
      "use-average-values": "Käytä keskiarvoja",
      "yes": "kyllä",
      "no": "ei",
      "alarm": "Hälytys",
      "off": "pois",
      "on": "päällä",
      "ok": "ok",
      "cancel": "peru",
      "co2-auto-calibration": "Hiilidioksidiarvon automaattinen kalibrointi",
      "alarm-sensitivity-settings": "Lämpötila, kosteus, VOC, CO2 hälytysherkkyyden asetus",
      "sec-0": "0",
      "sec-10": "10s",
      "sec-30": "30s",
      "sec-120": "2min",
      "lux-alarm-sens": "Valoisuushälytyksen herkkyyden asetus",
      "spl-alarm-sens": "Äänenpainehälytyksen herkkyyden asetus",
      "alarm-idle-type": "Hälytyksen tyyppi tyhjäkäynnillä",
      "regenerative": "Regenerative",
      "one-shot": "One-shot",
      "double-message": "Kaksoisviesti per hälytys",
      "not-yet-synced": "Asetuksia ei ole vielä synkronoitu MultiGuardin kanssa",
      "select-sensor": "Säädä hälytysrajoja klikkaamalla sensorikuvaketta",
      "alarm-limits": "Hälytysrajat",
      "tmp-alarm-limits": "Lämpötilan hälytysrajat",
      "hum-alarm-limits": "Kosteuden hälytysrajat",
      "voc-alarm-limits": "VOC-päästöjen hälytysrajat",
      "co2-alarm-limits": "Hiilidioksidipitoisuuden hälytysrajat",
      "lux-alarm-limits": "Valoisuuden hälytysrajat",
      "pir-alarm-limits": "Liiketunnistimen hälytysrajat",
      "spl-alarm-limits": "Äänenpaineen hälytysrajat",
      "pressure-alarm-limits": "Paineen hälytysrajat",
      "uplink-interval": "Viestien lähetysväli",
      "required": "Vaaditaan",
      "user-numbers": "Näihin numeroihin lähetetään hälytysviestit ja tehdään hälytyspuhelut.",
      "confirm": "Vahvista",
      "code": "koodi",
      "return": "Palaa",
      "update": "Päivitä",
      "remove-account": "Poista tili",
      "information-registered": "tiedot on nyt rekisteröity.",
      "numbers-to-save": "Tallenna nämä numerot puhelimeesi.",
      "sms-number": "SMS-numero",
      "voice-number": "Äänipuheluiden numero",
    
      "aaa_general": "Yleisesasetukset",
      "temperature": "Lämpötila",
      "humidity": "Kosteus",
      "voc": "VOC",
      "co2": "CO2",
      "pir": "Liike",
      "lux": "Valo",
      "spl": "Äänenpaine",
      "pressure": "Paine",
      "batteryVoltage": "Akku",

      // Setting and data labels
      "label-sAmao": "maksimiylitys",
      "label-sAmad": "maksimialitus",
      "label-sAhlt": "yläraja",
      "label-sAllt": "alaraja",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", 
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    debug: false,
  });

  export default i18n;